// 封装请求方法
import request from '@/api/request'

//记录浏览信息
export const browseRecord = (data) => {
  return request({
    url: '/app/home/browse/record',
    method: 'post',
    data: data,
  })
}
//联系信息查询
export const contactInfo = (data) => {
  return request({
    url: '/app/home/contact/info',
    method: 'post',
    data: data,
  })
}
//留言
export const messageLeave = (data) => {
  return request({
    url: '/app/home/message/leave',
    method: 'post',
    data: data,
  })
}
